import { Component, OnInit } from '@angular/core';
import { ToasterService, UserService } from '../../services';
import { ToasterLink, ToasterValidationMessage } from '../../models';

@Component({
    selector: 'mxc-toaster',
    templateUrl: 'toaster.component.html',
    styleUrls: ['./toaster.component.scss'],
})

export class ToasterComponent implements OnInit {
    messages: string[] | ToasterValidationMessage[];
    alertClass: string;
    link: ToasterLink;
    smallBannerStyle: boolean;
    constructor(
        private toasterService: ToasterService,
        private userService: UserService,
    ) { }

    ngOnInit(): void {
        this.toasterService.getNotification().subscribe((notification) => {
            if (typeof notification.messages === 'object' && notification.messages !== null && (!Array.isArray(notification.messages))) {
                this.messages = (Object.values(notification.messages) as any).flat();
            } else if (
                typeof notification.messages[0] === 'object'
                && notification.messages !== null
                && (!Array.isArray(notification.messages[0]))
            ) {
                this.messages = (Object.values(notification.messages[0]) as any).flat();
            } else {
                this.messages = notification.messages;
            }

            this.smallBannerStyle = notification.smallBannerStyle
            this.alertClass = `alert-${notification.type}`;
            if (notification.link) {
                this.link = notification.link;
            }
            if (notification.type === 'success') {
                setTimeout(() => { this.dismiss(); }, 5000);
            }
        });
    }

    dismiss(): void {
        this.messages = undefined;
    }
}
