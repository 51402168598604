import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpBackend } from '@angular/common/http';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { Observable, throwError } from 'rxjs';
import { ToasterService } from './toaster.service';
import { HttpCache } from '../interceptors/HttpCache';
import { environment } from 'environments/environment';
import {
    Login,
    Auth,
    JwtPayload,
    UserProfile,
    CurrentUserResponse,
    NewReferral,
    ToasterLink,
    EmailForwardingOptions,
    SuccessResponse,
    ChangePassword,
    ChangeQuestionForm,
    RedFlagResponse,
    EmailForwardingResponse,
    ChangeUserReponse,
    UserRegionsResponse,
    UserHomepageResponse,
    RevertUserResponse,
} from '../models';
import { catchError, map, retry } from 'rxjs/operators';
import { SecurityQuestionResponse } from '../../register/register.model';
import { LogService } from './log.service';
import * as Sentry from '@sentry/angular';
import * as moment from 'moment';
import { CustomerIoService } from '.';

@Injectable({
    providedIn: 'root',
})
export class UserService {

    jwtPayload: JwtPayload;
    authTokens: Auth;
    cookieKey = 'maxCntrJwtKey';
    domain: string;
    user: UserProfile;
    offset = 0;
    sentry = Sentry;
    sessionTimingOutWindow = 30; // minutes
    sessionTimingOutAlert = 5; // minutes

    constructor(
        private http: HttpClient,
        private toaster: ToasterService,
        private cookieService: CookieService,
        private logService: LogService,
        private handler: HttpBackend,
        private cache: HttpCache,
        private router: Router,
        private customerIoService: CustomerIoService,

        // the type was switched to 'any' since GA manipulates the window variable and we need to access it to add user attributes
        @Inject(Window) private window: any,
    ) {
        const maxcntrRegex = new RegExp(`^${environment.APP_PREFIX}\.maxcntr\.com$`);
        const remaxnetRegex = new RegExp(`^${environment.APP_PREFIX}\.remax\.net$`);
        if (maxcntrRegex.test(this.window.location.hostname)) {
            this.domain = `${environment.APP_PREFIX}.maxcntr.com`;
        } else if (remaxnetRegex.test(this.window.location.hostname)) {
            this.domain = `${environment.APP_PREFIX}.remax.net`;
        } else {
            this.domain = 'localhost';
        }
        const token = cookieService.get(this.cookieKey);
        const sessionToken = localStorage.getItem('sessionToken');
        const offsetCookie = cookieService.get('expOffset');

        if (token) {
            this.authTokens = { token, sessionToken };
            this.jwtPayload = this.parseJwt(token);
        }

        if (offsetCookie) {
            this.offset = +offsetCookie;
        }
    }

    private setHeader(): HttpHeaders {
        return new HttpHeaders({
            'Content-Type': 'application/json',
        });
    }

    attemptAuth(loginData: Login): Observable<Auth> {
        const http = new HttpClient(this.handler);
        this.window.analytics.track('Sign In Attempted', {
            remaxnet_email: loginData.email,
        });

        return http.post(`${environment.API_URL}/auth/login`, loginData, { headers: this.setHeader() })
            .pipe(
                catchError(err => {
                    this.saveAttemps();
                    return throwError(err);
                }),
                map((res: Auth) => !res.token ? res : this.saveTokens(res, loginData.email)),
            );
    }


    getExpirationAlertTime() {
        return moment(this.jwtPayload.exp * 1000);
    }

    extendSession(): Observable<any> {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${this.authTokens.token}`
        });
        const http = new HttpClient(this.handler);
        return http.get(`${environment.API_URL}/user/extend-session`, { headers: headers })
          .pipe(
            catchError(err => {
                return throwError(err);
            }),
            retry(2)
          );
    }

    parseJwt(token: string): JwtPayload {
        return JSON.parse(atob(token.split('.')[1]));
    }

    isLoggedIn(): boolean {
        if (this.jwtPayload && ((this.jwtPayload.exp * 1000) + this.offset) > Date.now() && this.cookieService.get(this.cookieKey)) {
            return true;
        } else if (this.jwtPayload) {
            this.toaster.setNotification({ type: 'danger', messages: ['Your session has expired.'] });
        }

        return false;
    }

    isSessionExpiringSoon(): boolean {
        const jwtExp = this.jwtPayload.exp * 1000;
        if (moment().add(this.sessionTimingOutWindow, 'm').isAfter(moment(this.jwtPayload.exp * 1000))) {
            return true;
        }
        return false;
    }

    saveTokens(tokens: Auth, loginEmail: String = ''): Auth {
        this.authTokens = tokens;
        this.jwtPayload = this.parseJwt(tokens.token);

        const diff = Date.now() - (tokens.timestamp * 1000);

        // If there is a 1 hour difference between local/server time we want to set an offset
        if (Math.abs(diff) >= (60 * 60 * 1000)) {
            this.offset = diff;
        }

        const expiresAt = new Date((this.jwtPayload.exp * 1000) + this.offset);

        this.cookieService.deleteAll();
        this.cookieService.set(this.cookieKey, tokens.token, expiresAt, '/', this.domain);
        this.cookieService.set('maxCntrJwtLastUpdated', Date.now().toString(), expiresAt, '/', this.domain);
        localStorage.setItem('sessionToken', tokens.sessionToken);
        if (this.offset) {
            this.cookieService.set('expOffset', this.offset.toString(), expiresAt, '/', this.domain);
        }

        this.resetAttemps();
        return tokens;
    }

    // get current user
    getMe(): Observable<UserProfile> {
        return this.http.get(`${environment.API_URL}/user`)
            .pipe(
                catchError(err => this.toaster.handleErrors(err)),
                map((res: CurrentUserResponse) => {
                    if (!this.user) {
                        const {
                            billDue: discard1,
                            eulaAccepted: discard2,
                            features: discard3,
                            personId: person_id,
                            id: user_id
                        } = res.data;

                        if (this.window.analytics) {
                            let primaryOffice = res.data['offices'].filter(office => {
                                return office.primary;
                            })[0];

                            if (!primaryOffice) { // It's possible to have no primary offices, so just default to first one in the list
                                primaryOffice = res.data['offices'][0];
                            }

                            let regionName = null;
                            let regionId = null;

                            if (typeof res.data['region'] !== 'undefined') {
                                regionName = res.data['region']['name'];
                                regionId = res.data['region']['id'];
                            }

                            this.window.analytics.identify(user_id, {
                                person_id: person_id,
                                first_name: res.data['firstName'],
                                last_name: res.data['lastName'],
                                isp_email: res.data['ispEmail'],
                                remaxnet_email: res.data['remaxnetEmail'],
                                groups: res.data['groups'],
                                title: res.data['title'],
                                class: res.data['class'],
                                primary_office_name: primaryOffice['name'],
                                primary_office_city: primaryOffice['city'],
                                primary_office_state: primaryOffice['state'],
                                primary_office_zip: primaryOffice['postalCode'],
                                country: res.data['country'],
                                region: regionName,
                                region_id: regionId,
                                being_impersonated: this.isImpersonating(),
                                impersonator: this.jwtPayload.impersonator,
                            });

                            Object.keys(res.data.groupAssociations).forEach((groupAssociation) => {
                                this.window.analytics.group(groupAssociation, {
                                    group_name: res.data.groupAssociations[groupAssociation],
                                });
                            });

                            this.window.analytics.track('Signed In', {
                                is_suspended: res.data['sos'],
                                bill_due: res.data['billDue'],
                                user_status: res.data['status'],
                                destination_url: localStorage.getItem('deepLink'),
                            });

                        }

                        this.getNewReferrals().subscribe(referrals => {
                            const link: ToasterLink = {
                                url: [`/roster/user/${res.data.id}/referrals/received`, { page: 1 }],
                                text: 'VIEW',
                            };

                            if (referrals.length > 1 && !res.data.sos) {
                                this.toaster.setNotification({
                                    type: 'success',
                                    messages: ['You have received new referrals'],
                                    link,
                                });
                            } else if (referrals.length === 1 && !res.data.sos) {
                                link.url.push(referrals[0].id);
                                this.toaster.setNotification({
                                    type: 'success',
                                    messages: [
                                        `You have received a referral from ${referrals[0].senderFirstName} ${referrals[0].senderLastName}`,
                                    ],
                                    link,
                                });
                            }
                        });
                    }

                    this.user = res.data;

                    this.setPrimaryOffice();
                    if (Array.isArray(this.window?.dataLayer) && !this.window.dataLayer.some(obj => obj?.hasOwnProperty('User ID'))) {
                        this.window.dataLayer.push({ 'User ID': this.user.id });
                        this.window.dataLayer.push({ 'Class Code': this.user.class });
                        this.window.dataLayer.push({ 'Title Code': this.user.title });
                    }

                    this.setSentryUser();
                    if (!this.isImpersonating()) {
                        this.customerIoService.identifyUser({
                            id: res.data.id,
                            created_at: res.data.createdAt,
                        });
                    }
                    return res.data;
                })
            );
    }

    // log out
    deauthenticate(): void {
        this.customerIoService.resetIdentification();
        this.cookieService.delete(this.cookieKey, '/', this.domain);
        localStorage.removeItem('sessionToken');
        localStorage.removeItem('dismissedModals');
        localStorage.removeItem('recordedLoginSession');
        this.authTokens = null;
        this.jwtPayload = null;
        this.user = null;
    }

    // USER SETTINGS //
    changePassword(data: ChangePassword): Observable<SuccessResponse> {
        return this.http.post(`${environment.API_URL}/auth/change-password/`, data)
            .pipe(
                catchError(err => this.toaster.handleErrors(err)),
                map((res) => {
                    this.toaster.setNotification({
                        type: 'success',
                        messages: ['You have successfully changed your password.'],
                    });
                    return res;
                }),
            );
    }

    getAccessStatus(userId: number): Observable<CurrentUserResponse> {
        return this.http.get(`${environment.API_URL}/user/access-status/${userId}`, {})
            .pipe(catchError(err => this.toaster.handleErrors(err)),
                map((res) => {
                    this.user = res.data;
                    return res;
                }),
            );
    }

    getAllQuestions(userId: number): Observable<SecurityQuestionResponse> {
        return this.http.get(`${environment.API_URL}/auth/recovery-questions/${userId}`, {})
            .pipe(catchError(err => this.toaster.handleErrors(err)));
    }

    getMyQuestion(oktaId: string): Observable<string> {
        return this.http.get(`${environment.API_URL}/auth/recovery-question/${oktaId}`, { responseType: 'text' })
            .pipe(catchError(err => this.toaster.handleErrors(err)));
    }

    changeQuestion(data: ChangeQuestionForm): Observable<SuccessResponse> {
        return this.http.put(`${environment.API_URL}/auth/security-question/`, data)
            .pipe(
                catchError((err) => {
                    let error = '';
                    if (err.error) {
                        if (typeof err.error === 'string') {
                            error = err.error;
                        }
                        if (!error && err.error.data) {
                            error = err.error.data;
                        }
                    }
                    if (!error) {
                        error = err.message || 'Unknown error.';
                    }

                    this.window.analytics.track('Security Question Updated', {
                        error_message: error,
                        is_successful: false,
                    });

                    return this.toaster.handleErrors(err);
                }),
                map((res) => {
                    this.window.analytics.track('Security Question Updated', {
                        error_message: null,
                        is_successful: true,
                    });

                    this.toaster.setNotification({
                        type: 'success',
                        messages: ['You have successfully changed your security question.'],
                    });
                    return res;
                }),
            );
    }

    clearQuestionCache(oktaId): void {
        this.cache.delete(`${environment.API_URL}/auth/recovery-question/${oktaId}`);
    }

    getSMSData(): Observable<RedFlagResponse> {
        return this.http.get(`${environment.API_URL}/redflag/`)
            .pipe(catchError(err => this.toaster.handleErrors(err)));
    }

    updateSMS(user): Observable<SuccessResponse> {
        return this.http.post(`${environment.API_URL}/redflag/`, { user })
            .pipe(
                catchError(err => this.toaster.handleErrors(err)),
            );
    }

    updateEmailForwarding(options: EmailForwardingOptions): Observable<EmailForwardingResponse> {
        return this.http.post(`${environment.API_URL}/user/email-options`, options)
            .pipe(
                catchError(err => this.toaster.handleErrors(err)),
                map(res => {
                    this.toaster.setNotification({
                        type: 'success',
                        messages: [res.data.successMessage],
                    });
                    return res;
                }),
            );
    }

    // check if user received new referrals
    getNewReferrals(): Observable<NewReferral[]> {
        return this.http.get(`${environment.API_URL}/user/new-referrals`)
            .pipe(
                catchError(err => this.toaster.handleErrors(err)),
                map(res => res.data),
            );
    }

    changeUser(username: string): Observable<ChangeUserReponse> {
        return this.http.post(`${environment.API_URL}/auth/change-user`, { username })
            .pipe(
                catchError(err => this.toaster.handleErrors(err)),
                map(res => {
                    this.saveTokens(res);
                    this.customerIoService.resetIdentification();
                    return res;
                }),
            );
    }

    isImpersonating(): boolean {
        if (this.jwtPayload && this.jwtPayload.impersonator) {
            return true;
        } else {
            return false;
        }
    }

    revertUser(): Observable<RevertUserResponse> {
        return this.http.post(`${environment.API_URL}/auth/stop-impersonation`, {})
            .pipe(
                catchError(err => this.toaster.handleErrors(err)),
                map(res => {
                    this.saveTokens(res);
                    return res;
                }),
            );
    }

    resetExpiredPassword(sessionToken: string, oldPassword: string, newPassword: string): Observable<Auth> {
        const http = new HttpClient(this.handler);
        return http.post(`${environment.API_URL}/auth/expired-password`, {
            sessionToken, oldPassword, newPassword,
        }).pipe(
            map(res => this.saveTokens(res)),
        );
    }

    getRegions(): Observable<UserRegionsResponse> {
        return this.http.get(`${environment.API_URL}/user/regions`)
            .pipe(catchError(err => this.toaster.handleErrors(err)));
    }

    getHomepage(): Observable<UserHomepageResponse> {
        return this.http.get(`${environment.API_URL}/user/homepage`)
            .pipe(catchError(err => this.toaster.handleErrors(err)));
    }

    postHomepage(homepage: any): Observable<UserHomepageResponse> {
        return this.http.post(`${environment.API_URL}/user/homepage`, homepage)
            .pipe(catchError(err => this.toaster.handleErrors(err)));
    }

    checkUserFeature(slug: string): boolean {
        return this.user.features.filter(feature => feature.slug === slug).length > 0;
    }

    // save the times user try to login
    saveAttemps(): number {
        let loginAttempsArr = this.getAttemps();
        loginAttempsArr.push(new Date().getTime().toString());
        loginAttempsArr = loginAttempsArr.slice(-5);
        localStorage.setItem('loginAttemps', loginAttempsArr.join(','));
        return loginAttempsArr.length;
    }

    getAttemps(): string[] {
        const loginAttemps: string | null = localStorage.getItem('loginAttemps');
        const loginAttempsArr: string[] = loginAttemps === null ? [] : loginAttemps.split(',');
        // data should expire after 15 mins
        const expireTime: number = new Date().getTime() - 15 * 60 * 1000;
        if (loginAttempsArr.length > 0 && parseInt(loginAttempsArr[loginAttempsArr.length - 1], 10) < expireTime) {
            return [];
        }
        return loginAttempsArr;
    }

    resetAttemps(): void {
        localStorage.removeItem('loginAttemps');
    }

    continueOrLogout(): void {
        if (!this.isLoggedIn()) {
            const url = window.location.pathname + window.location.search;
            const { location } = this.window;

            this.deauthenticate();
            this.router.navigate(['/login'], {
                queryParams: {
                    fromURI: `${location.protocol}//${location.hostname + (location.port ? `:${location.port}` : '')}${url}`,
                },
            });
        }
    }

    getDefaultAuthPage(): string {
        if (this.checkUserFeature('home')) {
            return 'home';
        }
        return 'apps';
    }

    setSentryUser(): void {
        this.sentry.setUser({
            email: this.user.remaxEmail,
            mcid: this.user.id,
        });
        // TODO: When the user feedback (crash report) modal is ready for implementation, uncomment this
        // this.configureSentryUserFeedback();
    }

    configureSentryUserFeedback(): void {
        this.sentry.showReportDialog({
            user: {
                email: this.user.remaxEmail,
                name: this.user.name,
            },
            labelSubmit: 'Submit Report',
        });
    }

    setPrimaryOffice(): void {
        this.user.primaryOffice = this.user.offices.find(office => {
            return office.primary === 1;
        });
        if (!this.user.primaryOffice) {
            this.user.primaryOffice = this.user.offices[0];
        }
    }

    dismissedModal(key): boolean {
        const dismissedModals = localStorage.getItem('dismissedModals');
        if ( ! dismissedModals ) {
            return false;
        }

        const modals = JSON.parse(dismissedModals);

        return modals[key] ? true : false;
    }

    setDismissedModal(key): void {
        const dismissedModals = localStorage.getItem('dismissedModals');
        const modals = dismissedModals ? JSON.parse(dismissedModals) : {};
        modals[key] = 1;
        localStorage.setItem('dismissedModals', JSON.stringify(modals));
    }

    userIsInGroup(group: string): boolean {
        return !!this.user.groups.find(item => item === group);
    }
}
